<template>
    <div class="grid grid-cols-12 gap-4">
        <div class="md:col-span-7 col-span-12 border border-gray-200 rounded-2xl shadow-lg p-4">
            <img :src="$route.params.voucher.foto" class="w-full rounded-xl object-fill" />
            <div class="font-bold text-primary text-xl text-center my-4">{{ $route.params.voucher.judul }}</div>
            <div class="font-semibold text-lg pt-4 border-t-2 border-gray-200">Description</div>
            <div class="mt-2 font-medium">{{ $route.params.voucher.deskripsi }}</div>
        </div>
        <div class="md:col-span-5 col-span-12">
            <div class="border border-gray-200 rounded-2xl shadow-lg p-4">
                <div class="font-bold text-lg text-primary">Voucher Detail</div>
                <div class="grid grid-cols-2 mt-4">
                    <div class="flex items-center">
                        <font-awesome-icon class="mr-5" icon="fa-regular fa-clock" size="lg" />
                        <div class="font-semibold text-gray-500">Valid until</div>
                    </div>
                    <div class="pl-2 text-primary font-bold">
                        {{ $moment($route.params.voucher.waktu_selesai).format('D MMMM YYYY') }}
                    </div>
                </div>
                <div class="grid grid-cols-2 mt-2">
                    <div class="flex items-center">
                        <img src="@/assets/icons/rp.png" class="mr-3" />
                        <div class="font-semibold text-gray-500">Min. Transaction</div>
                    </div>
                    <div class="pl-2 text-primary font-bold">{{ $route.params.voucher.minimal | toCurrency }}</div>
                </div>
                <button v-if="$route.params.voucher.redeemed == 1" class="button-submit w-full mt-4" @click="useVoucher" type="button">Use</button>
                <button v-else class="button-submit w-full mt-4" @click="claimVoucher" type="button">Claim</button>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AccountVoucherDetail',

        beforeRouteEnter(to, from, next) {
            if (Object.keys(to.params).length == 0) {
                next({ name: 'AccountVoucherIndex' });
            } else {
                next();
            }
        },

        created() {
            this.$store.commit('setBreadcrumbItems', [
                { text: 'Home', routeName: 'Home' },
                { text: 'Account', routeName: 'AccountProfile' },
                { text: 'Voucher', routeName: 'AccountVoucherIndex' },
                { text: this.$route.params.voucher.judul, routeName: 'AccountVoucherDetail' }
            ]);
        },
        methods: {
            useVoucher(){
                if(this.$route.params.voucher.kategori == "1"){
                    this.$router.push({
                        name: 'Rental',
                        params: {
                            type: 'safari',
                            category: 'water'
                        }
                    });
                }else if(this.$route.params.voucher.kategori == "2"){
                    this.$router.push({
                        name: 'ListPaket',
                        params: {
                            type: 'safari',
                            category: 'water'
                        }
                    });
                }else if(this.$route.params.voucher.kategori == "3"){
                    this.$router.push({
                        name: 'ListPaket',
                        params: {
                            type: 'safari',
                            category: 'land'
                        }
                    });
                }else if(this.$route.params.voucher.kategori == "4"){
                    this.$router.push({
                        name: 'ListPaket',
                        params: {
                            type: 'safari',
                            category: 'land'
                        }
                    });
                }else if(this.$route.params.voucher.kategori == "5"){
                    this.$router.push({
                        name: 'ListPaket',
                        params: {
                            type: 'safari',
                            category: 'sky'
                        }
                    });
                }
            },
            async claimVoucher() {
            try {
                const response = await this.$http.post(`${this.$apiTripweWeb}/voucher/claim`, {
                    code: this.$route.params.voucher.kode_voucher
                });
                if (response.data.status == 200){
                this.$toasted.global.success(response.data.message)
                } else {
                this.$toasted.global.error(response.data.message)
                }
            } catch (error) {
                this.$toasted.global.error(error);
            }
            }
        },
    };
</script>
